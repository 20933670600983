import React from 'react'
import { Helmet } from 'react-helmet'

import '../../styles/custom.scss'
import favicon from '../../assets/favicon.ico'

import Navbar from '../Navbar'
import Footer from '../Footer'

const Layout = ({ children }) => (
    <div className="container">
        <Helmet>
            <html lang="en" amp />
            <title>Ray Fu | Business Strategist & Web Developer</title>
            <meta
                name="description"
                content="I'm a business strategist and web developer based in NYC. I focus on growth strategy and digital operations. I help C-Level business leaders define priorities, develop roadmaps, and execute on their visions."
            />
            <meta
                name="keywords"
                content="Rui Fu, Ray Fu, Ray, Fu, Robotics Process Automation, Business Process Outsourcing, Automation, Operations Strategy, Operating Model Design, Insurtech Startups NYC, Fintech Startups NYC"
            />
            <meta name="author" content="Ray Fu" />
            <link rel="shortcut icon" href={favicon} />
        </Helmet>

        <Navbar />
        {children}
        <Footer />
    </div>
)

export default Layout
