import React from 'react'

const Section = ({ title, subtitle, children }) => (
    <section>
        <div className="row">
            <hr />
        </div>
        <div className="row">
            <div className="two-thirds column">
                <h2>
                    <span className="underline">{title}</span>
                </h2>
                <p className="subhead">{subtitle}</p>
            </div>
        </div>
        <div className="row">{children}</div>
    </section>
)

export default Section
