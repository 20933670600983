import React from 'react'

const Skill = ({ icon, title, description }) => (
    <div className="one-third column">
        <div className="row">
            <div className="icon" style={{ marginBottom: '1rem' }}>
                <img
                    src={icon}
                    alt="Icon"
                    width="48px"
                    style={{ marginRight: '1rem' }}
                />
                <span className="subhead">{title}</span>
            </div>
            <p>{description}</p>
        </div>
    </div>
)

export default Skill
