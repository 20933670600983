import React from 'react'

import Layout from '../components/Layout'
import Header from '../components/Header'
import ExpertiseSection from '../sections/Expertise'
// import WritingSection from '../sections/Writing'
import ContactSection from '../sections/Contact'

const IndexPage = () => (
    <Layout>
        <Header>
            <span className="underline">Hello, my name is Ray Fu.</span> I'm a
            business strategist & web developer based in NYC.
        </Header>
        {/* <WritingSection /> */}
        <ExpertiseSection />
        <ContactSection />
    </Layout>
)

export default IndexPage
