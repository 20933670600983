import React from 'react'

import logo from '../../assets/logo.png'

const Navbar = () => (
    <nav className="row">
        <a href="/" className="logo">
            <img src={logo} alt="Ray Fu Logo" width="36px" />
        </a>
    </nav>
)

export default Navbar
