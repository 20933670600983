import React from 'react'

const Footer = () => (
    <div>
        <hr className="hr-footer" />
        <footer>
            <div className="row">
                <i className="u-pull-right" style={{ margin: 0 }}>
                    &copy; 2018 Ray Fu
                </i>
            </div>
        </footer>
    </div>
)

export default Footer
