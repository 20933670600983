import React from 'react'

const Header = ({ children }) => (
    <section className="row">
        <div className="two-thirds column">
            <h1>{children}</h1>
        </div>
    </section>
)

export default Header
