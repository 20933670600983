import React from 'react'

const Contact = () => (
    <section>
        <div className="row">
            <hr />
        </div>
        <div className="row">
            <div className="two-thirds column">
                <h2>
                    <span className="underline">LET'S CONNECT</span>
                </h2>
                <p className="subhead">
                    I'm interested in working with startups and nonprofits.
                </p>
            </div>
        </div>
        <div className="content">
            <a className="content-item" href="mailto:rayfu7@gmail.com">
                <button className="button">Email</button>
            </a>
            <a className="content-item" href="https://calendar.rayfuu.com">
                <button className="button">Coffee</button>
            </a>
        </div>
    </section>
)

export default Contact
