import React from 'react'

import Section from '../../components/Section'
import Skill from './Skill'

import automateIcon from '../../assets/robot.png'
import operateIcon from '../../assets/operations.png'
import innovateIcon from '../../assets/innovate.png'

const title = 'EXPERTISE'
const subtitle =
    'I focus on growth strategy and digital operations. I help C-Level business leaders define priorities, develop roadmaps, and execute on their visions.'

const Expertise = () => (
    <Section title={title} subtitle={subtitle}>
        <div className="content">
            <div className="row">
                <Skill
                    icon={innovateIcon}
                    title="Innovate"
                    description="Accelerate your company's growth by investing in the right core competencies. Understand which tech trends are relevant and develop a long-term investment plan.  "
                />
                <Skill
                    icon={automateIcon}
                    title="Automate"
                    description="Reimagine your operations with an automated digital workforce. Use Robotics Process and Cognitive Automation to eliminate manual processes and unlock sustained cost savings.
"
                />
                <Skill
                    icon={operateIcon}
                    title="Operate"
                    description="Evaluate how and where critical work gets done. Transform your operating model to simplify your organization, align incentives, and uncover improvement opportunities "
                />
            </div>
        </div>
    </Section>
)

export default Expertise
